import { Empty } from 'antd';

import Layout from '~/components/Layout';

const Error404 = () => {
  return (
    <Layout>
      <Empty description="404 - 未找到你要查找的网页" />
    </Layout>
  );
};

export default Error404;
